export const API = {
  checkout: 'checkout',
  developers: 'developers',
  getDistance: 'getDistance',
  paid: 'paid',
  updateOrderStatus: 'updateOrderStatus',
  updateOrderItem: 'updateOrderItem',
  updateUserInfo: 'updateUserInfo',
  verifyUserExists: 'verifyUserExists',
  refundOrder: 'refundOrder',
  subscribeToDomainMessages: 'subscribeToDomainMessages',
  unSubscribeFromDomainMessages: 'unSubscribeFromDomainMessages',
  subscribeToClientMessages: 'subscribeToClientMessages',
  unSubscribeToClientMessages: 'unSubscribeToClientMessages',
  subscribeToClientLocationMessages: 'subscribeToClientLocationMessages',
  unSubscribeToClientLocationMessages: 'unSubscribeToClientLocationMessages',
  subscribeToUserMessages: 'subscribeToUserMessages',
  unSubscribeToUserMessages: 'unSubscribeToUserMessages',
  sendClientMessage: 'sendClientMessage',
  sendLocationMessage: 'sendLocationMessage',
  sendUserMessage: 'sendUserMessage',
  printOrder: 'printOrder',
  syncAuthUsers: 'syncAuthUsers',
  getUserStatistics: 'getUserStatistics',
  getClientOrderStatistics: 'getClientOrderStatistics',
  getOrdersForUser: 'getOrdersForUser',
  connect: 'connect',
  addRewardPoints: 'addRewardPoints',
  lookupAddress: 'lookupAddress',
  createStripeLoginLink: 'createStripeLoginLink',
  isFirstOrder: 'isFirstOrder',
  submitFeedback: 'submitFeedback',
  updateFeedbackStatus: 'updateFeedbackStatus',
};
