const VERSION = require('../../package.json').version;

export const environment = {
  domain: null,
  production: true,
  version: VERSION,
  trackjs: {
    application: 'dev',
    token: '971bc65f7b5d4226a38a7a2b0c5f4004',
    version: VERSION,
  },
  firebase: {
    apiKey: 'AIzaSyD1piv6JZPFZU75OoHtA5OyUQ3SV443Irw',
    authDomain: 'demothreebapps.firebaseapp.com',
    databaseURL: 'https://demothreebapps.firebaseio.com',
    projectId: 'demothreebapps',
    storageBucket: 'demothreebapps.appspot.com',
    messagingSenderId: '79225739097',
    appId: '1:79225739097:web:7a85ec6857bd412e191e48',
  },
};
